
import request from '@/utils/request'
// 用户密码重置
export function updateUserPwd(data) {
    return request({
      url: '/api/user/manager/password',
      method: 'put',
      data
    })
  }
// 查询分页
export function listPage(params) {
    return request({
      url: "/api/messageNotice/listPage",
      params,
      method: "get",
    }) 
  }
  //修改状态
  export function updatestatus(data) {
    return request({
      url: "/api/messageNotice/status",
      data,
      method: "post"
    })
  }
  //查看详情
  export function detail(id) {
    return request({
        method:'get',
        url:`/api/messageNotice/detail/${id}`,
    });
  }
  //未读消息数
  export function getmessageNUmber(params){
    return request({
        url: "/api/messageNotice/getUnreadNumber",
        params,
        method: "get",
      }) 
  }
  //最近未读消息
  export function getLatestMsg(params){
    return request({
        url: "/api/messageNotice/getLatestMsg",
        params,
        method: "get",
      }) 
  }
  //读消息
  export function read(data) {
    return request({
      url: "/api/messageNotice/readAll",
      data,
      method: "post"
    })
  }
  //修改
  export function update(data) {
    return request({
      url: "/api/messageNotice/update",
      data,
      method: "put"
    })
  }
//新增
export function messageadd(data) {
    return request({
      url: "/api/messageNotice/add",
      data,
      method: "post"
    })
  }
  //删除
  export function deleteMessage(id) {
    return request({
      url: "/api/messageNotice/delete/" + id,
      method: "delete"
    })
}
//获取个人信息
export function getPersonInfo(params){
    return request({
        url: "/api/user/manager/getPersonInfo",
        params,
        method: "get",
      }) 
}

// 编辑用户昵称/手机号/微信解绑
export function editUserInfo(data) {
  return request({
    url: "/api/user/manager/update",
    data,
    method: "put"
  })
}

// 获取【绑定】二维码
export function getBindingTicket() {
  return request({
    url: "/api/user/getBindingTicket",
    method: "post"
  })
}

// 【绑定】二维码是否扫描
export function whetherScan(data) {
  return request({
    url: "/api/user/whetherBindingScan",
    data,
    method: "post"
  })
}