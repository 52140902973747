<template>
  <div class="stat">
    <el-container>
      <el-header style="overflow: hidden">
        <div class="left">
          <div class="logo">
            <img src="../assets/img/loginlogo2.png" alt="" />
          </div>
          <div
            :class="asideShow ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
            @click="setAsideShow"
            style="font-size: 32px; margin-right: 23px; color: #888888"
          ></div>

          <el-dropdown
            placement="bottom"
            trigger="click"
            @command="handleCommand"
          >
            <div
              class="iconfont icon-caidan1"
              style="font-size: 25px; margin-right: 20px; color: #888888"
            ></div>
            <el-dropdown-menu style="" slot="dropdown">
              <el-dropdown-item command="jump"
                ><img
                  src="../assets/img/logo.png"
                  style="
                    height: 18px;
                    vertical-align: middle;
                    margin-right: 5px;
                  "
                  alt=""
                />筋斗云OMS</el-dropdown-item
              >
              <el-dropdown-item style="color: #409eff; background: #e4e7ed"
                ><img
                  src="../assets/img/logoCircular.png"
                  style="width: 18px; vertical-align: middle; margin-right: 5px"
                  alt=""
                />筋斗云ERP</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <!-- 自定义菜单 -->
          <div class="minList" style="height: 50px; overflow: auto">
            <el-menu
              router
              :default-active="$route.path"
              mode="horizontal"
              background-color="#FFFFFF"
              text-color="#888888"
              style="font-size: 25px"
              active-text-color="#E9423A"
            >
              <!-- <el-menu-item index="1">处理中心</el-menu-item> -->
              <el-menu-item
                :index="item.url"
                v-for="item in topList"
                :key="item.id"
              >
                {{ item.name }}
              </el-menu-item>
            </el-menu>
          </div>
          <div
            class="el-icon-plus"
            @click="shortcutShow"
            style="font-size: 21px"
          ></div>
        </div>
        <div class="right">
          <!-- <h1 class="title" style="marginright: 20px">CloudKinTo</h1> -->
          <div class="message">
            <el-badge :value="messagenumber" class="item">
              <el-button type="text" @click="message">
                <span class="el-icon-message bbb"> </span>
              </el-button>
            </el-badge>
          </div>

          <div class="head">
            <img :src="headUrl" alt="" @click="image" />
          </div>
          <span style="margin-right: 20px; font-size: 20px; color: #888888">{{
            this.$store.state.user.userName
          }}</span>
          <div class="">
            <el-dropdown trigger="click" @command="handleCommand">
              <span class="el-icon-setting"> </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="set">设置</el-dropdown-item>
                <el-dropdown-item command="exitLogin"
                  >退出登录</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <el-container>
        <el-aside :width="leftWidth">
          <el-menu
            :default-active="$route.path"
            router
            :unique-opened="true"
            :collapse-transition="ani"
            :collapse="asideShow"
            class="el-menu-vertical-demo"
            background-color="#888888"
            text-color="#fff"
            @open="handleOpen"
            @close="handleClose"
            active-text-color="#ffd04b"
          >
            <template v-for="item in menuList">
              <el-menu-item
                :key="item.id"
                :index="item.url"
                v-if="item.children.length < 1"
              >
                <i
                  :class="item.icon"
                  style="margin-right: 10px; color: white"
                ></i>
                <span slot="title">{{ item.name }}</span>
              </el-menu-item>
              <el-submenu :key="item.id" :index="item.id + ''" v-else>
                <template slot="title">
                  <i
                    :class="item.icon"
                    style="margin-right: 10px; color: white"
                  ></i>
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item
                  v-for="child in item.children"
                  :index="child.url"
                  :key="child.id"
                >
                  <i
                    :class="child.icon"
                    style="margin-right: 10px; color: white"
                  ></i>
                  <span>{{ child.name }}</span>
                </el-menu-item>
              </el-submenu>
            </template>
          </el-menu>
        </el-aside>
        <el-main>
          <transition name="el-zoom-in-top">
            <!-- 自定义快捷方式 -->
            <el-card class="shortcut" v-if="shortcut" name="el-fade-in-linear">
              <el-form ref="form" :model="form" label-width="80px">
                <el-form-item
                  style="margin: 0"
                  v-for="item in menuList"
                  :key="item.id"
                  :label="item.name"
                >
                  <el-checkbox-group v-model="form.type">
                    <el-checkbox
                      v-if="item.children.length == 0"
                      :label="item.id"
                    >
                      {{ item.name }}
                    </el-checkbox>
                    <el-checkbox
                      v-else
                      v-for="child in item.children"
                      :key="child.id"
                      :label="child.id"
                    >
                      {{ child.name }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-row :gutter="20">
                  <el-col :span="12" :offset="18">
                    <el-button @click="shortcutShow">取消</el-button>
                    <el-button :loading="loading" type="primary" @click="sure"
                      >确定</el-button
                    >
                  </el-col>
                </el-row>
              </el-form>
            </el-card>
          </transition>

          <div class="bread" style="overflow: auto">
            <el-tabs
              closable
              v-model="editableTabsValue"
              @tab-click="tabpick"
              @tab-remove="removeTab"
            >
              <el-tab-pane
                :key="index"
                v-for="(item, index) in option"
                :label="item.title"
                :name="item.url"
              >
              </el-tab-pane>
            </el-tabs>
          </div>
          <keep-alive exclude="adv">
            <router-view class="ss"></router-view>
          </keep-alive>
          <!-- <router-view class="ss" v-if="!$route.meta.keepAlive"></router-view> -->
        </el-main>
      </el-container>
      <!-- 未读消息弹框 -->
      <el-dialog
        width="1140px"
        title="未读消息"
        :visible.sync="unreadMessage"
        :close-on-click-modal="false"
      >
        <el-form
          ref="formInline"
          :inline="true"
          :model="unreadList"
          class="demo-form-inline"
          label-position="left"
          label-width="80px"
        >
          <el-form-item
            label="消息标题"
            prop="messageTitle"
            style="width: 700px"
          >
            <el-input
              disabled
              cols="72%"
              type="textarea"
              v-model="unreadList.messageTitle"
              :autosize="{ minRows: 1, maxRows: 5 }"
              resize="none"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="消息正文"
            prop="messageContent"
            style="width: 700px"
          >
            <el-input
              disabled
              cols="72%"
              type="textarea"
              v-model="unreadList.messageContent"
              :autosize="{ minRows: 1, maxRows: 5 }"
              resize="none"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="消息类型"
            prop="messageType"
            style="width: 800px"
          >
            <el-input
              disabled
              cols="72%"
              type="textarea"
              v-model="unreadList.messageType"
              :autosize="{ minRows: 1, maxRows: 5 }"
              resize="none"
            ></el-input>
          </el-form-item>
          <el-form-item label="发布时间" prop="createTime">
            <el-input
              disabled
              cols="72%"
              type="textarea"
              v-model="unreadList.createTime"
              :autosize="{ minRows: 1, maxRows: 5 }"
              resize="none"
            ></el-input>
          </el-form-item>
          <div style="text-align: right; margin-top: 30px">
            <el-button @click="unreadMessage = false">取 消</el-button>

            <el-button type="primary" @click="unreadMessage = false"
              >确认</el-button
            >
          </div>
        </el-form>
      </el-dialog>
    </el-container>
  </div>
</template>

<script>
import { getmessageNUmber,getLatestMsg } from "@/api/personaldata.js";
import { exitLogin, leftMenu, topInit, addTopList } from "@/api/login.js";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      unreadMessage:false,
      unreadList:{},
      messagenumber: "",
      loading: false,
      asideShow: false,
      leftWidth: "170px",
      ani: true,
      menuList: [],
      shortcut: false,
      form: {
        type: [],
      },
      topIDprimary: [],
    };
  },

  created() {
    this.getmessageNUmber();
    this.getLatestMsg();
    this.leftMenu();
    this.topInit();
    this.$store.commit("shuaxin");
    this.$store.commit("setActiveTab", this.$route.path);
    this.menuList = JSON.parse(window.sessionStorage.getItem("menuList"));
  },
  mounted() {
    // console.log(this.$store.state.user);
  },
  computed: {
    headUrl() {
      // console.log(this.$store.state.user.headUrl);
      return this.$store.state.user.headUrl;
    },
    option() {
      return this.$store.state.crumbs;
    },
    editableTabsValue: {
      get() {
        return this.$store.state.activeTab;
      },
      set(val) {
        this.$store.commit("setActiveTab", val);
      },
    },
    topList() {
      const topList = [];
      this.topIDprimary.forEach((item) => {
        this.menuList.forEach((eve) => {
          if (eve.children.length == 0) {
            if (item == eve.id) {
              topList.push({ name: eve.name, url: eve.url });
            }
            return;
          }
          eve.children.forEach((child) => {
            if (item == child.id) {
              topList.push({ name: child.name, url: child.url });
            }
          });
        });
      });
      return topList;
    },
  },

  watch: {
    $route(to) {
      const option = this.option;
      let flag = false;
      if (to.meta.title) {
        for (let item of option) {
          if (item.url == to.path) {
            flag = true;
            this.$store.commit("setActiveTab", to.path);
            break;
          }
        }
      }
      if (!flag) {
        this.$store.commit("setCrumbs", {
          url: to.path,
          title: to.meta.title,
          name: to.path,
        });
        this.$store.commit("setActiveTab", to.path);
      }
    },
    immediate: true,
    deep: true,
  },

  methods: {
    getLatestMsg(){
      getLatestMsg().then((res) => {
        if (res.data.code == 200) {
          // this.unreadMessage = true;
          this.unreadList = res.data.data
        }else{
          this.unreadMessage = false;
        }
      });
    },
    getmessageNUmber() {
      getmessageNUmber().then((res) => {
        if (res.data.code == 200) {
          this.messagenumber = res.data.data;
        }
      });
    },
    topInit() {
      topInit().then((res) => {
        if (res.data.code == 200) {
          this.form.type = res.data.data;
          this.topIDprimary = this.form.type;
        }
      });
    },
    leftMenu() {
      leftMenu().then((res) => {
        this.$store.commit("setUser", {
          headUrl: res.data.data.headUrl,
          userAccount: res.data.data.userAccount,
          userName: res.data.data.userName,
        });
        window.sessionStorage.setItem("token", res.data.data.sessionId);
        window.sessionStorage.setItem(
          "menuList",
          JSON.stringify(res.data.data.menuTree)
        );
        this.menuList =
          JSON.parse(window.sessionStorage.getItem("menuList")) || [];
      });
    },
    shortcutShow() {
      this.shortcut = !this.shortcut;
      this.menuList =
        JSON.parse(window.sessionStorage.getItem("menuList")) || [];
      // this.form.type=JSON.parse(window.localStorage.getItem('topId'))||[]
    },
    tabpick(e) {
      let path = this.editableTabsValue;
      this.$store.state.crumbs.forEach((item) => {
        if (item.url == path) {
          this.$router.replace(item.url);
        }
      });
    },
    removeTab(targetName) {
      let option = this.option;
      console.log(option);
      option.forEach((item, index) => {
        if (item.url == targetName) {
          if (this.editableTabsValue == item.url) {
            this.$router.replace(this.option[index - 1].url);
            this.$store.commit("remove", index);
          } else {
            this.$store.commit("remove", index);
          }
        }
      });
    },
    setAsideShow() {
      this.asideShow = !this.asideShow;
      if (!this.asideShow) {
        this.leftWidth = "170px";
      } else {
        this.leftWidth = "52px";
      }
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleCommand(command) {
      if (command == "exitLogin") {
        exitLogin().then((res) => {
          if (res.data.code == 200) {
            window.sessionStorage.clear();
            this.$router.replace("/login");
            Cookies.remove("token");
            this.$message.success("退出成功"); // 暂时这样提示
          }
        });
      }
      if (command == "jump") {
        const token = window.sessionStorage.getItem(token);
        Cookies.set("token", token, {
          domain: ["localhost", "127.0.0.1", "192.168.1.111"].includes(
            document.domain
          )
            ? document.domain
            : ".cloudkinto.net",
        });
        window.location.href = "http://oms.cloudkinto.net/stat";
      }
      if (command == "set") {
        this.$router.push("/menuManagement");
      }
    },
    image() {
      this.$router.push("/personaldata");
    },
    message() {
      this.$router.push("/personaldata");

      // this.$router.push({
      //   name:'personaldata',
      //   params : {
      //     activeName:'fourth'
      //   }
      // })
    },
    sure() {
      if (this.form.type.length > 12) {
        this.$message({
          type: "warning",
          message: "快捷方式最大12个",
        });
        return;
      }
      this.loading = true;
      addTopList({ menuIds: this.form.type }).then((res) => {
        this.topIDprimary = this.form.type;
        if (res.data.code == 200) {
          this.topInit();
          this.shortcut = false;
          this.loading = false;
        } else {
          this.$message({
            type: "warning",
            message: res.data.message,
          });
          this.loading = false;
          return;
        }
      });
    },
  },
  beforeDestroy() {
    Cookies.remove("token"); //================================试用
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-menu-item {
  height: 50px;
  line-height: 50px;
}
.el-icon-plus {
  font-weight: 700;
  color: #888888;
}
.shortcut {
  position: fixed;
  z-index: 27;
  top: 59px;
  width: 800px;
}
::v-deep .bread {
  padding: 0;
  background: white;
  z-index: 11;
  // position: fixed;
  width: 100%;
  padding-top: 9px;
  padding-left: 13px;
  margin-left: -13px;
  overflow: hidden !important;
  .el-icon-close {
    display: none;
  }
}
::v-deep .bread {
  .el-tabs__item:hover {
    position: relative;
    .el-icon-close {
      display: inline-block;
      position: absolute;
      top: 10px;
    }
  }
}
.ss {
  // height: auto;
  margin-top: 20px;
  // position: relative;
  // top: 60px;
  // bottom: 0;
  // overflow: auto;
}
.stat {
  width: 100%;
  height: 100%;
  // overflow: hidden;
}
::v-deep .el-tabs__header {
  margin: 0;
}
.title {
  font-size: 30px;
  padding-right: 30px;
}
.stat {
  width: 100%;
  height: 100%;
}
.el-container {
  height: 100%;
  overflow: auto;
}
.el-main {
  // padding: 13px;
  // padding-top: 9px;
  // background: #F1F1F1;
  padding: 0 13px 13px 13px;
  // position: relative;
  height: 100%;
  transition: all 0.25s;
  background: white;
  position: relative;
}
.el-header {
  padding: 0;
  height: 50px !important;
  // width: 100%;
  background: #ffffff;
  color: white;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  .left {
    display: flex;
    align-items: center;
    .el-icon-s-fold,
    .el-icon-s-unfold {
      font-size: 20px;
      margin-left: 14px;
    }
  }
  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    .message {
      width: 30px;
      height: 30px;
      .bbb{
        font-size: 30px;
        margin-top: -9px;
        margin-left: -20px;
        color: #888888;
      }
    }
    .head {
      width: 30px;
      height: 30px;
      margin-right: 12px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .el-icon-setting {
      font-size: 26px;
      color: #888888;
      margin-right: 15px;
    }
  }
}
::v-deep .el-aside {
  height: 100%;
  background: #888888;
  color: white;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: all 0.25s;
  -webkit-transition: width 0.25s;
  -moz-transition: width 0.25s;
  -webkit-transition: width 0.25s;
  -o-transition: width 0.25s;
  .el-submenu__title i {
    color: rgba(255, 255, 255, 0.5);
  }
}
.el-aside::-webkit-scrollbar {
  width: 0 !important;
}

.sidebar {
  overflow-y: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent;
  -ms-scrollbar-track-color: transparent;
}
.logo {
  margin: 0 !important;
  height: 50px;
  background-color: #e9423a;
  img {
    height: 70%;
    width: 140px;
    padding: 8px 15px;
  }
}
.el-menu {
  border: 0;
}
::v-deep .el-tabs__item {
  padding-right: 20px;
  height: 50px;
  line-height: 32px;
  transition: all 1s;
}
::v-deep .el-tabs__nav {
  padding-bottom: 9px;
  height: 30px;
  .el-tabs__item:nth-last-of-type(1) {
    padding-right: 20px;
  }
}
.minList::-webkit-scrollbar {
  display: none;
}
::v-deep .bread .el-tabs__item.is-active {
  color: #e9423a;
}
::v-deep .bread .el-tabs__active-bar {
  background-color: #e9423a;
  width: 5px;
}
.el-submenu__icon-arrow ::v-deep .el-icon-arrow-down {
  color: #ffffff;
}
::v-deep .el-scrollbar__wrap {
  overflow: scroll;
  width: 110%;
  height: 120%;
}
::v-deep .input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>